var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"5037adfea750f79e87c74473df3ea324560b385b"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;
const thisEnv = process.env.NEXT_PUBLIC_ENV ?? 'no-environment-found';

const getEnvLabel = () => {
  if (thisEnv === 'PROD') {
    return 'production';
  }
  // Can re add dev if needed down the line.
  // if (thisEnv === 'DEV') {
  //   return 'development';
  // }
  return thisEnv.toLowerCase();
};

if (['UAT', 'PROD'].includes(process.env.NEXT_PUBLIC_ENV)) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: getEnvLabel(),
    integrations: [
      new Sentry.Replay({
        blockAllMedia: false,
        maskAllInputs: true,
        maskAllText: false
      })
    ],
    tracesSampleRate: 0.9,
    sampleRate: 1,
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1,
    beforeSendTransaction: (event) => {
      if (
        event.request?.headers?.['user-agent'] ===
        'Maker.tech Custom Algolia Crawler'
      ) {
        return null;
      }
      return event;
    },
    beforeSend: (event) => {
      if (event.tpye === 'replay' && !event.exception) {
        return null;
      }
      return event;
    },
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    ignoreErrors: [
      'Invariant: attempted to hard navigate to the same URL',
      'possibly because the user denied permission',
      'Error: Extension context invalidated.',
      '<unknown>',
      'TypeError: Uu().getRestrictions is not a function.',
      'ReferenceError: LCM is not defined',
      "ReferenceError: Can't find variable: _AutofillCallbackHandler",
      'SecurityError: Blocked a frame with origin',
      'TypeError: sessionStorage is null',
      'TypeError: undefined is not an object',
      'IndexSizeError: The index is not in the allowed range.'
    ]
  });
}
